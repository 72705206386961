<template>
  <div>
    <p class="mb-3">
      Which is a correct structure for
      <stemble-latex content="$\ce{N2O3,}$" />
      assuming that all atoms obey the octet rule (except for those that clearly exceed the octet)?
      Note that all lone pairs have been intentionally omitted.
    </p>

    <p class="mb-2 pl-3">
      <img src="/img/assignments/N2O3_LS.png" width="95%" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question328',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
      },
      options: [
        {text: '$\\text{Structure A}$', value: 'a'},
        {text: '$\\text{Structure B}$', value: 'b'},
        {text: '$\\text{Structure C}$', value: 'c'},
        {text: '$\\text{Structure D}$', value: 'd'},
        {text: '$\\text{Structure E}$', value: 'e'},
      ],
    };
  },
};
</script>
